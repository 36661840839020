import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import css from './AuthLandingPage.module.css';

import myService from './images/public-service.png';
import editProfile from './images/edit.png';
import asyncService from './images/customer-service.png';
import transactions from './images/transaction.png';
import lessons from './images/book.png';
import browseRequest from './images/browse_request.png';
import tips from './images/tips.png';
import recommended from './images/recommendation.png';

import { NamedLink } from '../../../components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUserCircle, 
  faEdit, 
  faUsers, 
  faSearch, 
  faShoppingCart, 
  faBook, 
  faLightbulb, 
  faStar, 
  faEnvelope,
  faEnvelopeOpen,
  faSearchPlus,
  faChessKing,
  faChessRook,
  faChessBishop,
  faList
} from '@fortawesome/free-solid-svg-icons';


const AsideTabPanel = props => {
  const { 
    className, 
    rootClassName, 
    isOpen, 
    currentPage, 
    currentUser, 
    isReviewer,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={css.asideTabPanel}>
        <div className={css.tabLinkWrapper}>
          {currentUser && currentUser.id && currentUser.id.uuid ? (
              <NamedLink name="ProfilePage" params={{ id: currentUser.id.uuid }} className={css.tabLink} target="_blank">
          <FontAwesomeIcon icon={faBook} size="2x" className={css.tabLinkIcon} />
                <span>My services</span>
              </NamedLink>
            ) : <></>
          }  
          <NamedLink name="ProfileSettingsPage" className={css.tabLink} target="_blank">
          <FontAwesomeIcon icon={faEdit} size="2x" className={css.tabLinkIcon} />
            <span>Edit my profile</span>
          </NamedLink>

          {isReviewer ? (
            <NamedLink name="RecommendedUsersPage" className={css.tabLink}>
          <FontAwesomeIcon icon={faChessRook} size="2x" className={css.tabLinkIcon} />
              <span>Recommended Users</span>
            </NamedLink>
          ) : (
            <NamedLink name="RecommendedUsersPage" className={css.tabLink}>
          <FontAwesomeIcon icon={faChessBishop} size="2x" className={css.tabLinkIcon} />
              <span>Recommended Coaches</span>
            </NamedLink>
          )}

          {isReviewer ? (
            <NamedLink name="RecommendedRequestsPage" className={css.tabLink}>
          <FontAwesomeIcon icon={faList} size="2x" className={css.tabLinkIcon} />
              <span>Recommended Requests</span>
            </NamedLink>
          ) : (
            <NamedLink name="RecommendedRequestsPage" className={css.tabLink}>
          <FontAwesomeIcon icon={faList} size="2x" className={css.tabLinkIcon} />
              <span>Recommended Services</span>
            </NamedLink>
          )}
          {isReviewer ? (
            <NamedLink name="SearchPage" to={{search: '?pub_listingType=review-request'}} className={css.tabLink} target="_blank">
           <FontAwesomeIcon icon={faChessKing} size="2x" className={css.tabLinkIcon} />
              <span>All requests</span>
            </NamedLink>
          ) : ( 
            <NamedLink name="SearchPage" to={{search: '?pub_listingType=reviewer-profile'}} className={css.tabLink} target="_blank">
          <FontAwesomeIcon icon={faChessKing} size="2x" className={css.tabLinkIcon} />
              <span>All Coaches</span>
            </NamedLink>
          )}
          <NamedLink name="InboxPage" params={{ tab: 'orders' }} className={css.tabLink} target="_blank">
          <FontAwesomeIcon icon={faEnvelopeOpen} size="2x" className={css.tabLinkIcon} />
            <span>My active transactions</span>
          </NamedLink>
          <NamedLink name="InboxBasePage" className={css.tabLink} target="_blank">
          <FontAwesomeIcon icon={faEnvelope} size="2x" className={css.tabLinkIcon} />
            <span>My historical transactions</span>
          </NamedLink>
          <NamedLink name="HowItWorks" className={css.tabLink} target="_blank">
          <FontAwesomeIcon icon={faLightbulb} size="2x" className={css.tabLinkIcon} />
            <span>How Chessvia Works</span>
          </NamedLink>
        </div>
      </div>
    </>
  );
};

AsideTabPanel.defaultProps = {
  className: null,
  rootClassName: null,
};

AsideTabPanel.propTypes = {
  className: string,
  rootClassName: string,
};

export default AsideTabPanel;
