import React, { useState, useEffect } from 'react';	
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import css from './AuthLandingPage.module.css';
import { Avatar } from '../../../components';
import { queryTxns, queryListings } from '../LandingPage.duck.js';

const AuthDetailPanelComponent = props => {
  const { 
  	className, 
  	rootClassName, 
  	currentPage, 
  	currentUser,
    handleQueryTxns,
    handleQueryListings,
  	isReviewer 
  } = props;

  const [txnNo, setTxnNo] = useState(0);
  const [numberOfListings, setNumberOfListings] = useState(0);
  const [chessviaRating, setChessviaRating] = useState(1500);
  const [chessviaScore, setChessviaScore] = useState(0);

  useEffect(() => {
    handleQueryTxns(isReviewer).then(res => setTxnNo(res));
    handleQueryListings(currentUser, isReviewer).then(res => setNumberOfListings(res));
    getChessviaRating();
  }, [currentUser])

  useEffect(() => {
    getChessviaScore(); 
  }, [chessviaRating, txnNo])

  const classes = classNames(rootClassName || css.root, className);
  const userFullName = currentUser?.attributes?.profile?.firstName + ' ' + currentUser?.attributes?.profile?.lastName;

  const getChessviaRating = () => {
    try {
      const { attributes: { profile: { publicData } } } = currentUser || {};
      const { chessAccountRating, lichessAccountRating } = publicData || {};

      const maxChessComRating = chessAccountRating.map(r => {
        if (r.title == 'Rapid') {
          return r.rating - 250
        } else if (r.title == 'Blitz') {
          return r.rating
        } else if (r.title == 'Bullet') {
          return r.rating - 200
        }
      });
      const maxLichessRating = lichessAccountRating.map(r => {
        if (r.title == 'Rapid') {
          return r.rating - 250
        } else if (r.title == 'Blitz') {
          return r.rating - 200
        } else if (r.title == 'Bullet') {
          return r.rating - 100
        }
      });

      const maxRating = Math.max(...maxChessComRating, ...maxLichessRating);
      setChessviaRating(maxRating);
    } catch (e) {
      setChessviaRating(1500);
    }
  }

  const getChessviaScore = () => {
    let score = 0;
    if (isReviewer) {
      score = chessviaRating + txnNo * 5;
    } else {
      score = chessviaRating + ((txnNo + numberOfListings) * 5);
    }
    setChessviaScore(score);
  }

  return (
    <div className={css.AuthDetailPanel}>
      <div className={css.mainPanelWrapper}>
      	<div className={css.userRow}>
      		<div className={css.userSection}>
      			<div className={css.profileImgBox}>
      				<Avatar className={css.avatar} user={currentUser} disableProfileLink />
      			</div>
      			{isReviewer ? (
      				<div className={css.userTitle}>Coach</div>
      			) : (
      				<div className={css.userTitle}>User</div>
      			)}
      			<div className={css.userName}>{userFullName}</div>
	        </div>
	        <div className={css.userDetails}>
	        	<h2 className={css.columnTitle}>Your Chessvia Dashboard</h2>
	        	<div className={css.dashboardContentRow}>
              <div className={css.dashboardContentBox}>
                <div className={css.boxTitle}>Number of Transactions: {txnNo}</div>
              </div>
              <div className={css.dashboardContentBox}>
                <div className={css.boxTitle}>Chessvia Rating: {chessviaRating}</div>
              </div>
              <div className={css.dashboardContentBox}>
                {isReviewer ? (
                    <div className={css.boxTitle}>Number of Listings: {numberOfListings}</div>
                  ) : (
                    <div className={css.boxTitle}>Number of Requests: {numberOfListings}</div>
                  )
                }
              </div>
              <div className={css.dashboardContentBox}>
                <div className={css.boxTitle}>Chessvia Score: {chessviaScore}</div>
              </div>
            </div>
	        </div>
      	</div>

      	{/*<div className={css.userOtherDetails}>
      		<div className={css.detailBox}>
      			<div>Content Here</div>
      		</div>
      		<div className={css.detailBox}>
      			<div>Content Here</div>
      		</div>
      		<div className={css.detailBox}>
      			<div>Content Here</div>
      		</div>
      	</div>*/}
      </div>  
    </div>
  );
};

AuthDetailPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

AuthDetailPanelComponent.propTypes = {
  className: string,
  rootClassName: string,
};

const mapDispatchToProps = dispatch => ({
  handleQueryTxns: (isReviewer) => dispatch(queryTxns(isReviewer)),
  handleQueryListings: (currentUser, isReviewer) => dispatch(queryListings(currentUser, isReviewer)),
});

const mapStateToProps = state => {
  return {
    
  };
};

const AuthDetailPanel = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AuthDetailPanelComponent);


export default AuthDetailPanel;
