import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import {
  H3,
  ProfileCard,
} from '../../../components';

import css from './RecommendedUsersPage.module.css';

export const RecommendedUsersComponent = props => {
  const { recommendedUsers, fetchRecommendedUsersProgress, fetchRecommendedUsersError, intl } = props;

  const recommendationsLoaded = !fetchRecommendedUsersProgress;

  const loadingResults = (
    <div className={css.messagePanel}>
      <H3 as="h2" className={css.heading}>
        <FormattedMessage id="RecommendedUsersPage.loadingRecommendedUsers" />
      </H3>
    </div>
  );

  const fetchingError = (
    <div className={css.messagePanel}>
      <H3 as="h2" className={css.heading}>
        <FormattedMessage id="RecommendedUsersPage.queryError" />
      </H3>
    </div>
  );

  const noResults =
    recommendationsLoaded && recommendedUsers.length === 0 ? (
      <H3 as="h1" className={css.heading}>
        <FormattedMessage id="RecommendedUsersPage.noResults" />
      </H3>
    ) : null;

  const heading =
    recommendationsLoaded && recommendedUsers.length > 0 ? (
      <H3 as="h1" className={css.heading}>
        <FormattedMessage
          id="RecommendedUsersPage.youHaveRecommendedUsers"
          values={{ count: recommendedUsers.length }}
        />
      </H3>
    ) : (
      noResults
    );

  const title = intl.formatMessage({ id: 'RecommendedUsersPage.title' });

  const panelWidth = 62.5;
  // Render hints for responsive image
  const renderSizes = [
    `(max-width: 767px) 100vw`,
    `(max-width: 1920px) ${panelWidth / 2}vw`,
    `${panelWidth / 3}vw`,
  ].join(', ');

  return (
    <>
      {fetchRecommendedUsersProgress && loadingResults}
      {fetchRecommendedUsersError && fetchingError}
      <div className={css.coachPanel}>
        {heading}
        <div className={css.coachCards}>
          {recommendedUsers.map(coach =>
            <ProfileCard
              className={css.profileCard}
              key={coach.id.uuid}
              user={coach}
              renderSizes={renderSizes}
            />
          )}
        </div>
      </div>
    </>
  );
};

RecommendedUsersComponent.defaultProps = {
  recommendedUsers: [],
  fetchRecommendedUsersError: null,
};

const { arrayOf, bool } = PropTypes;

RecommendedUsersComponent.propTypes = {
  recommendedUsers: arrayOf(propTypes.user),
  fetchRecommendedUsersProgress: bool.isRequired,
  fetchRecommendedUsersError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    recommendedUsers,
    fetchRecommendedUsersProgress,
    fetchRecommendedUsersError,
  } = state.RecommendedUsersPage;

  return {
    recommendedUsers,
    fetchRecommendedUsersProgress,
    fetchRecommendedUsersError,
  };
};

const RecommendedUsers = compose(
  connect(mapStateToProps),
  injectIntl
)(RecommendedUsersComponent);

export default RecommendedUsers;
